import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICohortAnnouncement, ICohortDocs, ICohortReadOnlyDocs, ICohortSession, ICohortState, ICohortSubmission, ICohortUngroupedDocs } from "../../../utils/types/cohort";




const initialState:ICohortState = {
    id:null,
    title:null,
    description:null,
    startDate:null,
    updatedBy:null,
    weeks:null,
    zoomAccount:null,
    cycleDay:1,
    cycleTime:null,
    details: {
        start: '',
        end: '',
        title:''
    }
};




export const cohortSlice = createSlice({
    name: 'cohort',
    initialState,
    reducers:{
        set_cohort:(state,action:PayloadAction<ICohortState>)=>{
            state.id = action.payload.id;
            state.title = action.payload.title;
            state.description = action.payload.description;
            state.weeks = action.payload.weeks;
            state.startDate = action.payload.startDate;
            state.updatedBy = action.payload.updatedBy;
            state.details = action.payload.details;
            state.details = action.payload.details;
            state.zoomAccount = action.payload.zoomAccount;
            state.cycleDay = action.payload.cycleDay;
            state.cycleTime = action.payload.cycleTime;
        },
        update_top_level_keys_cohort:(state,action:PayloadAction<{key:string,value:any}[]>)=>{
            action.payload.forEach(({key,value})=>{
                // @ts-ignore
                state[key] = value;
            });
       },
       remove_cohort_ungrouped_doc_page:(state,action:PayloadAction<{id:string,week:number}>) => {
        if(state.weeks){
            const new_pages = state.weeks[`${action.payload.week}` as keyof typeof state.weeks].ungroupedDocs.filter((t:ICohortUngroupedDocs)=>t.id!==action.payload.id);
            state.weeks[`${action.payload.week}` as keyof typeof state.weeks].ungroupedDocs = new_pages;
        };
       },
       re_order_cohort_knowledge_docs_pages:(state,action:PayloadAction<{items:ICohortDocs[],week:number,id:string}>)=>{
        if(state.weeks){
            const base_item = state.weeks[`${action.payload.week}` as keyof typeof state.weeks].docGroups;
            const item_index = base_item.findIndex((i:ICohortReadOnlyDocs)=>i.id===action.payload.id);
            if(item_index>=0){
                base_item[item_index].docs = action.payload.items;
            };
        };
      },
      update_cohort_ungroup:(state,action:PayloadAction<{docs:ICohortDocs[],week:number}>) => {
        if(state.weeks){
            const base_item = state.weeks[`${action.payload.week}` as keyof typeof state.weeks];
            base_item.ungroupedDocs = action.payload.docs;
        };
      },
      add_cohort_ungrouped_knowledge_doc:(state,action:PayloadAction<{doc:ICohortDocs,week:number}>)=>{
        if(state.weeks){
            state.weeks[`${action.payload.week}` as keyof typeof state.weeks].ungroupedDocs.push(action.payload.doc);
        };
      },
      add_cohort_grouped_doc_page:(state,action:PayloadAction<{item:ICohortDocs,week:number,doc_group_id:string}>)=>{
        if(state.weeks){
            const base_item = state.weeks[`${action.payload.week}` as keyof typeof state.weeks].docGroups;
            const item_index = base_item.findIndex((i:ICohortReadOnlyDocs)=>i.id===action.payload.doc_group_id);
            if(item_index>=0){
                // @ts-ignore
                base_item[item_index].docs.push(action.payload.item);
            };
        };
      },
      add_cohort_grouped_knwoledge_doc:(state,action:PayloadAction<ICohortReadOnlyDocs>)=>{
        if(state.weeks){
            state.weeks[`${action.payload.week}` as keyof typeof state.weeks].docGroups.push(action.payload);
        };
      },
      delete_cohort_grouped_read_only_doc:(state,action:PayloadAction<{id:string,week:number|string}>)=>{
        if(state.weeks){
            const new_docs = state.weeks[`${action.payload.week}` as keyof typeof state.weeks].docGroups.filter((item:ICohortReadOnlyDocs) => item.id !== action.payload.id);
            state.weeks[`${action.payload.week}` as keyof typeof state.weeks].docGroups = new_docs;
        };
       },
       re_order_cohort_knowledge_docs:(state,action:PayloadAction<{items:ICohortReadOnlyDocs[],week:number,entity:'ungroupedDocs'|'docGroups'}>)=>{
        // @ts-ignore
        state.weeks[`${action.payload.week}` as keyof typeof state.weeks][`${action.payload.entity}`] = action.payload.items
       },
       add_cohort_announcement:(state,action:PayloadAction<ICohortAnnouncement>)=>{
        if(state.weeks){
            state.weeks[`${action.payload.week}` as keyof typeof state.weeks].announcements.push(action.payload);
        };
       },
       add_cohort_submission:(state,action:PayloadAction<ICohortSubmission>)=>{
        if(state.weeks){
            state.weeks[`${action.payload.week}` as keyof typeof state.weeks].submissions.push(action.payload);
        };
       },
       removed_cohort_grouped_doc_page:(state,action:PayloadAction<{group_id:string,id:string,week:number}>)=>{
        if(state.weeks){
            const base_item =  state.weeks[`${action.payload.week}` as keyof typeof state.weeks].docGroups;
            const item_index = state.weeks[`${action.payload.week}` as keyof typeof state.weeks].docGroups.findIndex((i:ICohortReadOnlyDocs)=>i.id===action.payload.group_id);
            
            const new_pages = base_item[item_index].docs.filter((s:ICohortDocs)=>s.id!==action.payload.id);
            state.weeks[`${action.payload.week}` as keyof typeof state.weeks].docGroups[item_index].docs= new_pages;
        };
       },
       delete_cohort_announcement:(state,action:PayloadAction<{id:string,week:number|string}>)=>{
            if(state.weeks){
                const new_announcements = state.weeks[`${action.payload.week}` as keyof typeof state.weeks].announcements.filter((item:ICohortAnnouncement) => item.id !== action.payload.id);
                state.weeks[`${action.payload.week}` as keyof typeof state.weeks].announcements = new_announcements;
            };
       },
       update_cohort:(state,action:PayloadAction<any&{applyForAll:boolean,entity:string,week:number|string}>)=>{
        if(state.weeks){
            const _id = action.payload.byId||action.payload.id;
            const base_item = state.weeks[`${action.payload.week}` as keyof typeof state.weeks];
            const item_index = base_item[`${action.payload.entity}` as keyof typeof base_item].findIndex((item:{id:string})=>item.id===_id);
            // console.log(item_index, action.payload.byId, action.payload.id, action.payload);
            if(item_index>=0){
                base_item[`${action.payload.entity}` as keyof typeof base_item][item_index] = {
                    ...base_item[`${action.payload.entity}` as keyof typeof base_item][item_index],
                    ...action.payload,
                };
                if(action.payload.applyForAll){
                    Object.keys(state.weeks).forEach((week:keyof typeof state.weeks)=>{
                        const sub_item =  state.weeks && state.weeks[`${week}` as keyof typeof state.weeks];
                        sub_item && sub_item[`${action.payload.entity}` as keyof typeof base_item].forEach((item:any)=>{
                            item.minsFromCycle = action.payload.minsFromCycle;
                        });
                    });
                };
            };
        }
      },
      update_cohort_week_title:(state,action:PayloadAction<{week:number,title:string}>)=>{
        if(state.weeks){
            state.weeks[`${action.payload.week}` as keyof typeof state.weeks].details.title = action.payload.title;
        };
      },
       update_cohort_by_key:(state,action:PayloadAction<{id:string,key:string,value:any,week:number|string,entity:string,helperId?:string|null}>)=>{
        if(state.weeks){
            const base_item = state.weeks[`${action.payload.week}` as keyof typeof state.weeks];
            const item_index = base_item[`${action.payload.entity}` as keyof typeof base_item].findIndex((item:{id:string})=>item.id===action.payload.id);
            if(item_index>=0&&state.weeks){
                if(action.payload.entity==='docGroups'&&action.payload.helperId){
                        // @ts-ignore
                        const sub_item_index = base_item[action.payload.entity as keyof typeof base_item][item_index].docs.findIndex((s:{id:string})=>s.id===action.payload.helperId);
                        // @ts-ignore
                        const item:any = base_item[action.payload.entity as keyof typeof base_item][item_index].docs[sub_item_index];
                        if(!item.__tmp__){
                            item.__tmp__ = {...item};
                        };
                        // @ts-ignore
                        base_item[`${action.payload.entity}` as keyof typeof base_item][item_index].docs[sub_item_index][action.payload.key] = action.payload.value;
                        return;
                };
                const item:any = base_item[action.payload.entity as keyof typeof base_item][item_index];
                if(!item.__tmp__){
                    item.__tmp__ = {...item};
                };
                // @ts-ignore
                base_item[`${action.payload.entity}` as keyof typeof base_item][item_index][action.payload.key] = action.payload.value;
            };
        }
    },
    delete_cohort_submission:(state,action:PayloadAction<{id:string,week:number|string}>)=>{
        if(state.weeks){
            const new_submissions = state.weeks[`${action.payload.week}` as keyof typeof state.weeks].submissions.filter((item:ICohortSubmission) => item.id !== action.payload.id);
            state.weeks[`${action.payload.week}` as keyof typeof state.weeks].submissions = new_submissions;
        };
    },
    delete_cohort_session:(state,action:PayloadAction<{id:string,week:number|string}>)=>{
        if(state.weeks){
            const new_sessions = state.weeks[`${action.payload.week}` as keyof typeof state.weeks].sessions.filter((item:ICohortSession) => item.id !== action.payload.id);
            state.weeks[`${action.payload.week}` as keyof typeof state.weeks].sessions = new_sessions;
        };
      },
       add_cohort_sessions:(state,action:PayloadAction<ICohortSession>)=>{
        if(state.weeks){
             state.weeks[`${action.payload.week}` as keyof typeof state.weeks].sessions.push(action.payload);
        };
      },
       toggle_edit_chort:(state,action:PayloadAction<{id:string,week:number|string,entity:string}>)=>{
        if(state.weeks){
            const base_item = state.weeks[`${action.payload.week}` as keyof typeof state.weeks];
            const item_index = base_item[`${action.payload.entity}` as keyof typeof base_item].findIndex((item:{id:string})=>item.id === action.payload.id);
            const item = base_item[`${action.payload.entity}` as keyof typeof base_item][item_index];
            if(item.isEdit&&item.__tmp__){
                base_item[`${action.payload.entity}` as keyof typeof base_item][item_index] = {...item.__tmp__,isEdit:false};
            }else{
                item.isEdit = !item.isEdit;
            }
        };
       }
    }
});

export const { update_cohort_week_title,set_cohort,update_top_level_keys_cohort,toggle_edit_chort,add_cohort_sessions,delete_cohort_session,update_cohort_by_key,update_cohort,delete_cohort_announcement,add_cohort_announcement,add_cohort_submission,delete_cohort_submission,re_order_cohort_knowledge_docs,re_order_cohort_knowledge_docs_pages,delete_cohort_grouped_read_only_doc,remove_cohort_ungrouped_doc_page,removed_cohort_grouped_doc_page,add_cohort_grouped_doc_page,add_cohort_ungrouped_knowledge_doc,add_cohort_grouped_knwoledge_doc,update_cohort_ungroup } = cohortSlice.actions;
export default cohortSlice.reducer;

