import {FC} from 'react';
import { createRoot } from 'react-dom/client';
import { domAnimation, LazyMotion } from 'framer-motion';
import { PersistGate } from 'redux-persist/integration/react';
import {persistStore} from 'redux-persist';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { ConfirmProvider } from 'material-ui-confirm';


import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './redux/store';
import App from './app';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


const container:HTMLElement|null = document.getElementById('root');
if(!container){
  throw new Error("container not found");
};

const root = createRoot(container);
const persistor = persistStore(store);

export {
  store
};

const Wrapper:FC = () => {
  return (
    // <React.StrictMode>
        <LazyMotion strict features={domAnimation}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                     <SnackbarProvider>
                         <ConfirmProvider>
                            <App />
                          </ConfirmProvider>
                      </SnackbarProvider>
                </PersistGate>
            </Provider>
        </LazyMotion>
    // </React.StrictMode>

  )
};

root.render(<Wrapper />);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
