import { AnimatePresence } from 'framer-motion';
import React, {} from 'react';
import { Routes, Route,BrowserRouter } from "react-router-dom";

import { default_path, login_path } from './utils/configs/path';
import { admin_path, cohort_path, member_path, sprint_path, zoom_path } from './utils/configs/route';
import AuthProvider from './utils/providers/auth.provider';
import RequireAuth from './utils/require_auth';
import AuthWithSuspenseWrapper from './utils/wrappers/AuthWithSuspenseWrapper';



const Login = React.lazy(() => import('./pages/login'));
const SprintDatabase = React.lazy(() => import('./pages/sprint'));
const SprintDetails = React.lazy(() => import('./pages/sprint/details'));
const AddSprint  = React.lazy(()=>import('./pages/sprint/add.sprint'));

const CohotDatabase = React.lazy(()=>import('./pages/cohort'));
const AddCohort = React.lazy(()=>import('./pages/cohort/add.cohort'));
const CohortDetails = React.lazy(()=>import('./pages/cohort/details'));
const ZoomAccountDatabase = React.lazy(()=>import('./pages/zoom'));
const ConnectZoomAccount = React.lazy(()=>import('./pages/zoom/connect.zoom'));

const Admin = React.lazy(()=>import('./pages/admin'));
const AddAdmin = React.lazy(()=>import('./pages/admin/add.admin'));
const UpdateAdmin = React.lazy(()=>import('./pages/admin/update.admin'));


const MemberDatabase =  React.lazy(()=> import('./pages/member'));
const ProfileDetails =  React.lazy(()=> import('./pages/member/details'));
// const AddMember = React.lazy(()=>import('./common/addmembers'));



// creating a sperate App component,
// so when we have to add header and footer, we can just add it here

const App:React.FunctionComponent = () => {
    return (

            <BrowserRouter>
                <AuthProvider>
                    <AnimatedRoutes />
                </AuthProvider>
            </BrowserRouter>

    )
};  


const AnimatedRoutes = () => {
    // const location = useLocation();

    return (
        <AnimatePresence exitBeforeEnter>
            <Routes >
                <Route path={login_path} element={AuthWithSuspenseWrapper(Login)} />
                <Route path="/" element={<RequireAuth replace={default_path}/>} /> 
                {/* SPRINT TEMPLATES */}
                    <Route path={`/${sprint_path}`} element={AuthWithSuspenseWrapper(SprintDatabase)} />
                    <Route path={`/${sprint_path}/create`} element={AuthWithSuspenseWrapper(AddSprint)} />
                    <Route path={`/${sprint_path}/template/:id`} element={AuthWithSuspenseWrapper(SprintDetails)} />
                {/* END */}
                {/* COHORT */}
                    <Route path={`/${cohort_path}`} element={AuthWithSuspenseWrapper(CohotDatabase)} />
                    <Route path={`/${cohort_path}/create`} element={AuthWithSuspenseWrapper(AddCohort)} />
                    <Route  path={`/${cohort_path}/:id`} element={AuthWithSuspenseWrapper(CohortDetails)} />
                    {/* <Route path={`${cohort_path}/:id/add-member`} element={AuthWithSuspenseWrapper(AddMember)}/> */}
                {/* END */}
                {/* ZOOM ACCOUNT DATABASE */}
                     <Route path={`/${zoom_path}`} element={AuthWithSuspenseWrapper(ZoomAccountDatabase)} />
                     <Route path={`/${zoom_path}/connect`} element={AuthWithSuspenseWrapper(ConnectZoomAccount)} />
                {/* END */}
                
                {/* ADMIN */}
                      <Route path={`/${admin_path}`} element={AuthWithSuspenseWrapper(Admin)} />
                      <Route path={`/${admin_path}/add`} element={AuthWithSuspenseWrapper(AddAdmin)} />
                      <Route path={`/${admin_path}/update`} element={AuthWithSuspenseWrapper(UpdateAdmin)} />
                {/* END */}

                {/* MEMBERS */}
                     <Route path={`/${member_path}`} element={AuthWithSuspenseWrapper(MemberDatabase)}/>
                     <Route path={`/${member_path}/:id/*`} element={AuthWithSuspenseWrapper(ProfileDetails)}/>
                     {/* <Route path={`/${member_path}/add`} element={AuthWithSuspenseWrapper(AddMember)}/> */}
                {/* END */}

            </Routes>
           
        </AnimatePresence>
    )
};



export default App;