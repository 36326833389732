import {combineReducers,Middleware} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
import authSlice from './features/auth/auth.slice';
import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';
import sprintSlice from './features/sprint/sprint.slice';
import cohortSlice from './features/cohort/cohort.slice';


const middlewares:Middleware[] = [];

// only to log redux actions in dev env
if (process.env.NODE_ENV === `development`) {
    const { logger } = require('redux-logger');
    middlewares.push(logger);
 };

 const reducers = combineReducers({
    auth:authSlice,
    sprints:sprintSlice,
    cohort:cohortSlice
});

const persistConfig = {
    key: 'learnershub_admin_root',
    version:1,
    // blacklist:[],
    whitelist:['auth'],
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware:middlewares
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;