import { createSlice,PayloadAction } from '@reduxjs/toolkit';
import { IAnnouncement, IDocs, IReadOnlyDocs, ISession, ISprintState, ISubmission } from '../../../utils/types/sprint';



const initialState:ISprintState = {
    id:null,
    title:null,
    description:null,
    weeks:null
};


export const sprintSlice = createSlice({
    name: 'sprint',
    initialState,
    reducers:{
       update_top_level_keys:(state,action:PayloadAction<{key:string,value:any}[]>)=>{
            action.payload.forEach(({key,value})=>{
                // @ts-ignore
                state[key] = value;
            });
       },

       re_order_knowledge_docs:(state,action:PayloadAction<{items:IReadOnlyDocs[],week:number,entity:'ungroupedDocs'|'docGroups'}>)=>{
            // @ts-ignore
            state.weeks[`${action.payload.week}` as keyof typeof state.weeks][`${action.payload.entity}`] = action.payload.items
       },
       re_order_knowledge_docs_pages:(state,action:PayloadAction<{items:IDocs[],week:number,id:string}>)=>{
            if(state.weeks){
                const base_item = state.weeks[`${action.payload.week}` as keyof typeof state.weeks].docGroups;
                const item_index = base_item.findIndex((i:IReadOnlyDocs)=>i.id===action.payload.id);
                if(item_index>=0){
                    base_item[item_index].docs = action.payload.items;
                };
            };
       },
       delete_week:(state,action:any) => {
            const weeks = state.weeks||{};
            delete weeks[action.payload];
            state.weeks = {...weeks};
      },
       add_new_week:(state,action:any) => {
            const new_week = Object.keys(state.weeks||{}).length+1;
            state.weeks = {
                ...state.weeks,
                [new_week]:{
                    sessions:[],
                    announcements:[],
                    docGroups:[],
                    ungroupedDocs:[],
                    submissions:[]
                }
            }
       },
       set_sprint:(state,action:PayloadAction<ISprintState>)=>{
              state.id = action.payload.id;
              state.title = action.payload.title;
              state.description = action.payload.description;
              state.weeks = action.payload.weeks;
       },

       add_grouped_knwoledge_doc:(state,action:PayloadAction<IReadOnlyDocs>)=>{
            if(state.weeks){
                state.weeks[`${action.payload.week}` as keyof typeof state.weeks].docGroups.push(action.payload);
            };
       },
       add_ungrouped_knowledge_doc:(state,action:PayloadAction<{doc:IDocs,week:number}>)=>{
            if(state.weeks){
                state.weeks[`${action.payload.week}` as keyof typeof state.weeks].ungroupedDocs.push(action.payload.doc);
            };
       },
       add_grouped_doc_page:(state,action:PayloadAction<{item:IDocs,week:number,doc_group_id:string}>)=>{
            if(state.weeks){
                const base_item = state.weeks[`${action.payload.week}` as keyof typeof state.weeks].docGroups;
                const item_index = base_item.findIndex((i:IReadOnlyDocs)=>i.id===action.payload.doc_group_id);
                if(item_index>=0){
                    // @ts-ignore
                    base_item[item_index].docs.push(action.payload.item);
                };
            };
       },
       removed_grouped_doc_page:(state,action:PayloadAction<{group_id:string,id:string,week:number}>)=>{
            if(state.weeks){
                const base_item =  state.weeks[`${action.payload.week}` as keyof typeof state.weeks].docGroups;
                const item_index = state.weeks[`${action.payload.week}` as keyof typeof state.weeks].docGroups.findIndex((i:IReadOnlyDocs)=>i.id===action.payload.group_id);
                
                const new_pages = base_item[item_index].docs.filter((s:IDocs)=>s.id!==action.payload.id);
                state.weeks[`${action.payload.week}` as keyof typeof state.weeks].docGroups[item_index].docs= new_pages;
            };
       },

       remove_ungrouped_doc_page:(state,action:PayloadAction<{id:string,week:number}>) => {
            if(state.weeks){
                const new_pages = state.weeks[`${action.payload.week}` as keyof typeof state.weeks].ungroupedDocs.filter((t:IDocs)=>t.id!==action.payload.id);
                state.weeks[`${action.payload.week}` as keyof typeof state.weeks].ungroupedDocs = new_pages;
            };
       },
       add_announcement:(state,action:PayloadAction<IAnnouncement>)=>{
            if(state.weeks){
                state.weeks[`${action.payload.week}` as keyof typeof state.weeks].announcements.push(action.payload);
            };
       },

       add_submission:(state,action:PayloadAction<ISubmission>)=>{
            if(state.weeks){
                state.weeks[`${action.payload.week}` as keyof typeof state.weeks].submissions.push(action.payload);
            };
       },
       add_sessions:(state,action:PayloadAction<ISession>)=>{
           if(state.weeks){
                state.weeks[`${action.payload.week}` as keyof typeof state.weeks].sessions.push(action.payload);
           };
       },
       update_ungroup:(state,action:PayloadAction<{docs:IDocs[],week:number}>) => {
            if(state.weeks){
                const base_item = state.weeks[`${action.payload.week}` as keyof typeof state.weeks];
                base_item.ungroupedDocs = action.payload.docs;
                
            };
       },
       update:(state,action:PayloadAction<any&{applyForAll:boolean,entity:string,week:number|string}>)=>{
            if(state.weeks){
                const _id = action.payload.byId||action.payload.id;
                const base_item = state.weeks[`${action.payload.week}` as keyof typeof state.weeks];
                const item_index = base_item[`${action.payload.entity}` as keyof typeof base_item].findIndex((item:{id:string})=>item.id===_id);
                // console.log(item_index, action.payload.byId, action.payload.id, action.payload);
                if(item_index>=0){
                    base_item[`${action.payload.entity}` as keyof typeof base_item][item_index] = {
                        ...base_item[`${action.payload.entity}` as keyof typeof base_item][item_index],
                        ...action.payload,
                    };
                    if(action.payload.applyForAll){
                        Object.keys(state.weeks).forEach((week:keyof typeof state.weeks)=>{
                            const sub_item =  state.weeks && state.weeks[`${week}` as keyof typeof state.weeks];
                            sub_item && sub_item[`${action.payload.entity}` as keyof typeof base_item].forEach((item:any)=>{
                                item.minsFromCycle = action.payload.minsFromCycle;
                            });
                        });
                    };
                };
            }
        },
        update_by_key:(state,action:PayloadAction<{id:string,key:string,value:any,week:number|string,entity:string,helperId?:string|null}>)=>{
            if(state.weeks){
                const base_item = state.weeks[`${action.payload.week}` as keyof typeof state.weeks];
                const item_index = base_item[`${action.payload.entity}` as keyof typeof base_item].findIndex((item:{id:string})=>item.id===action.payload.id);
                if(item_index>=0&&state.weeks){
                    if(action.payload.entity==='docGroups'&&action.payload.helperId){
                            // @ts-ignore
                            const sub_item_index = base_item[action.payload.entity as keyof typeof base_item][item_index].docs.findIndex((s:{id:string})=>s.id===action.payload.helperId);
                            // @ts-ignore
                            const item:any = base_item[action.payload.entity as keyof typeof base_item][item_index].docs[sub_item_index];
                            if(!item.__tmp__){
                                item.__tmp__ = {...item};
                            };
                            // @ts-ignore
                            base_item[`${action.payload.entity}` as keyof typeof base_item][item_index].docs[sub_item_index][action.payload.key] = action.payload.value;
                            return;
                    };
                    const item:any = base_item[action.payload.entity as keyof typeof base_item][item_index];
                    if(!item.__tmp__){
                        item.__tmp__ = {...item};
                    };
                    // @ts-ignore
                    base_item[`${action.payload.entity}` as keyof typeof base_item][item_index][action.payload.key] = action.payload.value;
                };
            }
        },
        delete_grouped_read_only_doc:(state,action:PayloadAction<{id:string,week:number|string}>)=>{
            if(state.weeks){
                const new_docs = state.weeks[`${action.payload.week}` as keyof typeof state.weeks].docGroups.filter((item:IReadOnlyDocs) => item.id !== action.payload.id);
                state.weeks[`${action.payload.week}` as keyof typeof state.weeks].docGroups = new_docs;
            };
        },
        delete_submission:(state,action:PayloadAction<{id:string,week:number|string}>)=>{
            if(state.weeks){
                const new_submissions = state.weeks[`${action.payload.week}` as keyof typeof state.weeks].submissions.filter((item:ISubmission) => item.id !== action.payload.id);
                state.weeks[`${action.payload.week}` as keyof typeof state.weeks].submissions = new_submissions;
            };
        },
        delete_session:(state,action:PayloadAction<{id:string,week:number|string}>)=>{
            if(state.weeks){
                const new_sessions = state.weeks[`${action.payload.week}` as keyof typeof state.weeks].sessions.filter((item:ISession) => item.id !== action.payload.id);
                state.weeks[`${action.payload.week}` as keyof typeof state.weeks].sessions = new_sessions;
            };
        },
        delete_announcement:(state,action:PayloadAction<{id:string,week:number|string}>)=>{
            if(state.weeks){
                const new_announcements = state.weeks[`${action.payload.week}` as keyof typeof state.weeks].announcements.filter((item:IAnnouncement) => item.id !== action.payload.id);
                state.weeks[`${action.payload.week}` as keyof typeof state.weeks].announcements = new_announcements;
            };
        },
        
        toggle_edit:(state,action:PayloadAction<{id:string,week:number|string,entity:string}>)=>{
            if(state.weeks){
                const base_item = state.weeks[`${action.payload.week}` as keyof typeof state.weeks];
                const item_index = base_item[`${action.payload.entity}` as keyof typeof base_item].findIndex((item:{id:string})=>item.id === action.payload.id);
                const item = base_item[`${action.payload.entity}` as keyof typeof base_item][item_index];
                if(item.isEdit&&item.__tmp__){
                    base_item[`${action.payload.entity}` as keyof typeof base_item][item_index] = {...item.__tmp__,isEdit:false};
                }else{
                    item.isEdit = !item.isEdit;
                }
            };
        }
    }
});

export const { add_sessions, update, delete_session,toggle_edit,update_by_key,set_sprint,add_announcement,delete_announcement,update_top_level_keys,add_submission,delete_submission,re_order_knowledge_docs,add_grouped_knwoledge_doc,add_grouped_doc_page,delete_grouped_read_only_doc,re_order_knowledge_docs_pages,removed_grouped_doc_page,add_ungrouped_knowledge_doc,remove_ungrouped_doc_page,update_ungroup,add_new_week,delete_week } = sprintSlice.actions;
export default sprintSlice.reducer;


