import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../providers/auth.provider";

const withRouterAuth = (Component:React.ComponentClass<any>) => {
    const ComponentWithRouterProp = (props:any) => {
      const location = useLocation();
      const navigate = useNavigate();
      const auth = useAuth();

      return (
        <Component {...props} router={{ location, navigate}} auth={auth}/>
      );
    }
  
    return ComponentWithRouterProp;
};


export default withRouterAuth;