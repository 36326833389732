// import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { default_path, login_path } from './configs/path';
import { useAuth } from './providers/auth.provider';

function RequireAuth({ children,replace }: { children?: JSX.Element|null,replace?:string }):any {
    let auth = useAuth();
    let location = useLocation();
    if(auth.user?.token&&location.pathname===login_path){
      return <Navigate to={default_path} state={{ from: location }}  replace />;
    };
    if (!auth.user?.token&&location.pathname!==login_path) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    };
    if(replace){
      return <Navigate to={`${replace}`} state={{ from: location }}  replace />;
    }
    if(!children){
      return <div/>
    };
    return children;
};


export default RequireAuth;