import { createSlice,PayloadAction } from '@reduxjs/toolkit';
import { IAuthState } from '../../../utils/types/auth';

const initialState:IAuthState = {
    token:null,
    refreshToken:null,
    user:null
};


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers:{
        on_user_login:(state,action:PayloadAction<IAuthState>)=>{
            state.token = action.payload.token;
            state.user = action.payload.user;
        },
        on_user_logout:(state)=>{
            state.token = null;
            state.user = null;
        }
    }
});

// Action creators are generated for each case reducer function
export const { on_user_login,on_user_logout } = authSlice.actions;

export default authSlice.reducer;