import React from 'react';
import { createSelector } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import SideBar from '../../common/SideBar';
import { on_user_login, on_user_logout } from '../../redux/features/auth/auth.slice';
import { RootState } from '../../redux/store';
import { AuthContextType, IAuthState } from '../types/auth';
import axios from 'axios';
// import { logout } from '../../actions/auth';




const AuthContext = React.createContext<AuthContextType>(null!);


const _user_ = createSelector((state:RootState)=>state.auth,(auth)=>auth);

function AuthProvider({children}:{children:React.ReactNode}) {

    const user = useSelector(_user_);
    const dispatch = useDispatch();

    // useEffect(()=>{
    //   const _tk = localStorage.getItem('tokens');
    //   const tokens =_tk?JSON.parse(_tk):{token:''};
    //   axios.defaults.headers.common['Authorization'] = `Bearer ${tokens.token}`;
    // },[]);




    const login = async(user: IAuthState):Promise<boolean> =>{
        return new Promise((resolve,reject)=>{
            // axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
            localStorage.setItem("tokens",JSON.stringify({token:user.token,refreshToken:user.refreshToken}));
            dispatch(on_user_login(user));
            resolve(true);
        });
    };  

    const signout = async():Promise<boolean> => {
        return new Promise(async(resolve,reject)=>{
            // logout();
            axios.defaults.headers.common['Authorization'] = '';
            dispatch(on_user_logout());
            localStorage.removeItem("tokens");
            resolve(true);
        });
    };


    const value = { user, login, signout };
    return (
      <AuthContext.Provider value={value}>
        <div className="flex flex-row">
          {user&&user.token&&(
              <SideBar />
          )}
          {children}
        </div>
      </AuthContext.Provider>
    );
};

function useAuth(){
  return React.useContext(AuthContext);
};
  
export default AuthProvider;
export { useAuth };