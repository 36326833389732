import React,{Fragment} from 'react';
import {Link, Location, NavigateFunction} from 'react-router-dom'
import itemList from '../utils/sidebar/itemList';
import logo from '../assets/logo.png';
import withRouter from '../utils/hooks/withRouterAuth';
import { AuthContextType } from '../utils/types/auth';
import { MenuIcon, XIcon } from '@heroicons/react/solid';
import { Dialog, Transition } from '@headlessui/react'


type SideBarProps = {
    router:{
        location:Location;
        navigate:NavigateFunction;
    },
    auth:AuthContextType
};

type SideBarState = { 
    is_side_drawer_visible:boolean
}

class SideBar extends React.Component<SideBarProps,SideBarState>{
    constructor(props:SideBarProps){
        super(props);
        this.state = {
            is_side_drawer_visible:false
        };
    }
    componentDidMount(){
    }
    shouldComponentUpdate(n:SideBarProps,s:SideBarState){
        if(this.state.is_side_drawer_visible!==s.is_side_drawer_visible){
            return true;
        };
        if(getPathName(n.router.location.pathname)!==getPathName(this.props.router.location.pathname)){
            return true;
        };
        return false;
    };

    render_categories = (items:typeof itemList[0],index:number) =>{
        return (
            <div key={`${index}`} >
                {items.map(this.render_rows)}
                {(index+1)<itemList.length&&<hr className="my-6 border-gray-200" /> }
            </div>
        );
    };

    render_rows = (item:typeof itemList[0][0]) => {
        return <Row key={item.id} selected={item.path===getPathName(this.props.router.location.pathname)} {...item}/>
    };

    _signout = async() => {
        await this.props.auth.signout(); 
        this.props.router.navigate("/login",{replace:true});
    };

    _open_side_drawer = (v:any) => {
        this.setState({is_side_drawer_visible:Boolean(v)})
    }
    _close_side_drawer = () => {
        this.setState({is_side_drawer_visible:false});
    };

    render(): React.ReactNode {
        return (
            <>
            {/* Side drawer */}
            <Transition.Root show={this.state.is_side_drawer_visible} as={Fragment}>
                <Dialog as="div" className="relative z-[101] lg:hidden" onClose={this._open_side_drawer}>
                    <Transition.Child as={Fragment} enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100"leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex z-40">
                        <Transition.Child as={Fragment} enter="transition ease-in-out duration-300 transform" enterFrom="-translate-x-full" enterTo="translate-x-0" leave="transition ease-in-out duration-300 transform" leaveFrom="translate-x-0" leaveTo="-translate-x-full">
                            <Dialog.Panel className="relative max-w-xs w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
                                <div className="px-4 pt-5 pb-2 flex">
                                    <button type="button" className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400" onClick={this._close_side_drawer}>
                                        <span className="sr-only">Close menu</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <img alt="logo" src={logo} className="w-32  self-start m-5"/>
                                <div className="flex flex-col justify-between flex-1">
                                    <nav>
                                        {itemList.map(this.render_categories)}
                                    </nav>

                                    <div onClick={this._signout} className="flex items-center px-4 -mx-2 cursor-pointer">
                                        <img className="object-cover mx-2 rounded-full h-9 w-9" src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" alt="avatar"/>
                                        <h4 className="mx-2 font-medium text-gray-800 hover:underline">{`${this.props.auth.user?.user?.firstName} ${this.props.auth.user?.user?.lastName}`}</h4>
                                    </div>
                                </div>

                            
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>


            <div className="flex border-b border-x-slate-600 md:w-1/5 w-auto">
                <div onClick={this._open_side_drawer} className="md:hidden flex bg-slate-200 border rounded-md ml-3 sm:mt-5 mt-3 z-[100] fixed">
                        <MenuIcon className="w-7 h-7 text-blue-500"/>
                </div>
                <div className="md:flex hidden flex-col w-full h-screen px-4 py-8 bg-white border-r sticky top-0">
                    <img alt="logo" src={logo} className="w-36 mb-2 self-start"/>
                    <div className="flex flex-col justify-between flex-1">
                        <nav>
                            {itemList.map(this.render_categories)}
                        </nav>
                        <div onClick={this._signout} className="flex items-center px-4 -mx-2 cursor-pointer">
                            <img className="object-cover mx-2 rounded-full h-9 w-9" src="https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80" alt="avatar"/>
                            <h4 className="mx-2 font-medium text-gray-800 hover:underline">{`${this.props.auth.user?.user?.firstName} ${this.props.auth.user?.user?.lastName}`}</h4>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}



interface IRowProps {
    title:string;
    icon:React.ReactNode;
    id?:string;
    path?:string;
    selected?:boolean;
};

const _Row = ({title,icon,selected,path}:IRowProps) => {
    return (
        <Link to={`/${path}`} className={`flex items-center px-4 py-2 mt-5 text-gray-600 transition-colors duration-200 transform md:rounded-md rounded-none hover:bg-blue-100 hover:text-gray-700 ${selected?'bg-blue-200':''}`}>
            {icon}
            <span className="mx-3 text-sm font-semibold">{title}</span>
        </Link>
    )  
};

function getPathName(path:string) {
    return path.split('/')[1];
};

const Row = React.memo(_Row);

export default withRouter(SideBar);